export default [
    "DTC_INTERPRETATIONS_BASIC",
    "DTC_INTERNALSHOWPAGEID",
    "DTC_COMPONENT_PREDICTION",
    "DTC_COMPONENT_PREDICTION_WITH_RMI",
    "DTC_COMPONENT_PREDICTION_FEEDBACK",
    "DTC_SOLUTION_PREDICTION",
    "DTC_CHATBOT",

    "RMI_BASIC",
    "RMI_GUIDE",
    "RMI_GUIDE_IMAGE", /* remove */
    "RMI_IMAGE",
    "RMI_INSPECTIONS (legacy)",
    "RMI_KTYPE_COMPONENT_MAPPING",
    "RMI_LOCATION_COMP",
    "RMI_LOCATION_FUSE",
    "RMI_MANUFACTURER_CAMPAIGNS",
    "RMI_RECALL",
    "RMI_REPAIR_INSTRUCTIONS",
    "RMI_SERVICE_SCHEDULES",
    "RMI_SERVICE_SCHEDULES_INSPECTIONS",
    "RMI_SERVICE_SCHEDULES_INTERVALS",
    "RMI_TECHNICAL_DATA",
    "RMI_REPAIR_TIMES",
    "RMI_WIRING_DIAGRAMS",

    "VIS_BASIC",
    "VIS_ENRICHED",
    "KTYPE_BASIC",
    "PIN_BASIC",
    "VIN_VEHICLES"
];
